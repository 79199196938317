import $ from "jquery";

$(function () {
    $(".c-navigation > .pll-parent-menu-item").click(function (e) {
        e.preventDefault();
        // limit click to children of mainmenue
        var $el = $("ul", this); // element to toggle
        $(".sub-menu").not($el).slideUp(); // slide up other elements
        $el.stop(true, true).slideToggle(200); // toggle element
        return false;
    });
    $(".sub-menu > li").click(function (e) {
        e.stopPropagation(); // stop events from bubbling from sub menu clicks
    });
});
jQuery;

$("input").unwrap();
$("textarea").unwrap();
