import "./style.scss";

/** VENDORS */
import "./js/vendor/swiper";
import "./js/vendor/gsap";
import "./js/vendor/locomotive";
// import "./js/vendor/scrollTrigger";
// import './js/vendor/custom-select';

/** COMPONENTS */
// import './js/select';
import "./js/slider";
// import './js/menuToggle';
// import './js/filter';
// import './js/search';
// import './js/stickyHeader';
// import './js/video';
// import './js/jumpToContact';
// import './js/triggerScripts';
// import './js/accordion';
import "./js/submenu";
import "./js/stickyHeader";
