const headerEl = document.querySelector(".s-header");
const backToTop = document.querySelector(".c-backToTop");
const wrapper = document.querySelector(".sticky");

const handlerHeaderSticky = (entries) => {
    console.log(entries);
    // entries is an array of observed dom nodes
    // we're only interested in the first one at [0]
    // because that's our .sentinal node.
    // Here observe whether or not that node is in the viewport
    if (!entries[0].isIntersecting) {
        headerEl.classList.add("active");
        backToTop.classList.add("active");
    } else {
        headerEl.classList.remove("active");
        backToTop.classList.remove("active");
    }
};

// create the observer
const observer = new window.IntersectionObserver(handlerHeaderSticky);
// give the observer some dom nodes to keep an eye on
observer.observe(wrapper);
